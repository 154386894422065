import React from "react";
//import "App.css";
//import React, { useState, useEffect } from "react";
/* 
import Feed from "components/feed/feedPage/feed";
import ReactGa from "react-ga";
import Home from "components/home/home";
import fire from "components/config/fire"; */

function App() {
  /* const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loggedIn, setloggedIn] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");

  const clearInputs = () => {
    setEmail("");
    setPassword("");
  };

  const clearErrors = () => {
    setEmailError("");
    setPasswordError("");
  };

  const handleLogin = (event) => {
    clearErrors();
    event.preventDefault();
    fire
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch((err) => {
        switch (err.code) {
          case "auth/invalid-email":
          case "auth/user-disabled":
          case "auth/user-not-found":
            setEmailError(err.message);
            break;
          case "auth/wrong-password":
            setPasswordError(err.message);
            break;
          default:
            break;
        }
      });
  };

  const writeUserData = (userId, firstName, lastName, userName, email) => {
    fire
      .database()
      .ref("users/" + userId)
      .set({
        firstname: firstName,
        lastname: lastName,
        username: userName,
        email: email,
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };

  const handleSignup = (event) => {
    clearErrors();
    event.preventDefault();
    fire
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(()=>{
        var userId = fire.auth().currentUser.uid;
        writeUserData(userId, firstName, lastName, userName, email);
      })
      .catch((err) => {
        console.log(err)
        switch (err.code) {
          case "auth/invalid-email":
          case "auth/email-already-in-use":
            setEmailError(err.message);
            break;
          case "auth/weak-password":
            setPasswordError(err.message);
            break;
          default:
            break;
        }
      });
  };

  const handleLogout = () => {
    fire.auth().signOut();
  };

  const authListener = () => {
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        clearInputs();
        setUser(user);
      } else {
        setUser("");
      }
    });
  };

  useEffect(() => {
    ReactGa.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);

    // Report page view
    ReactGa.pageview(window.location.pathname + window.location.search);

    authListener();
  }); */
  /* <div className="page-loader">
      {user ? (
        <Feed
        handleLogout={handleLogout}
        user={user}
        />
      ) : (
        <Home
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          handleLogin={handleLogin}
          handleSignup={handleSignup}
          loggedIn={loggedIn}
          setloggedIn={setloggedIn}
          user={user}
          emailError={emailError}
          passwordError={passwordError}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          userName={userName}
          setUserName={setUserName}
        />
      )}
    </div> */
  return (
  <div></div>
  );
}

export default App;
